.edit-channel__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.edit-channel__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* height: 62px; */
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  padding-right: 20px;
}

.edit-channel__header p {
  font-family: Helvetica Neue, sans-serif;
  font-weight: bold;
  font-size: 18px;
  /* line-height: 22px; */
  color: #2c2c30;
  padding: 21.5px;
}

.edit-channel__header svg {
  cursor: pointer;
}

.edit-channel__button-wrapper {
  background: #f7f6f8;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-bottom-right-radius: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .edit-channel__button-wrapper {
    display: flex;
    flex-direction: column;
  }
}

.edit-channel__button-wrapper p {
  font-family: Helvetica Neue, sans-serif;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  padding: 10px 20px;
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .edit-channel__button-wrapper p {
    width: 100%;
    border-radius: 0px;
  }
}