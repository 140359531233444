html {
  scroll-padding-top: 106px;
}

.Navbar {
  z-index: 999;
  color: black;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
}

.splash {
  background-image: url(./img/4313234.jpg);
}

.app {
  padding: '40px';
}

/* amplify-authenticator {
  background-color: #0C8159;
  color: red;
} */

:root {
  --amplify-background-color: #0f3637;
  --amplify-primary-color: #08f78f;
  --amplify-primary-tint: #6bfdbe;
  --amplify-primary-shade: #6bfdbe;
  --amplify-secondary-color: white;
  --amplify-secondary-contrast: #081b1b;
  background-color: #202020;
}

div.ReactTags__tags {
  position: relative;
}
/* Styles for the input */
div.ReactTags__tagInput {
  width: 100%;
  border-radius: 20px;
  display: flex;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 35px;
  margin: 0;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  padding: 0 4px;
}
/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  /* border: 1px solid #ddd; */
  background: #09E285;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  padding: 5px;
  margin: 0px 10px 20px 10px;
  cursor: move;
  border-radius: 2px;
}
div.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}