.channel__container {
  height: 100vh;
  width: 100%;
  background: #FFFFFF;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

@media only screen and (max-width: 767px) {
  .channel__container {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
}

.str-chat__thread {
  z-index: 1;
}

.str-chat__thread-list .str-chat__message-team {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.str-chat__thread-list .str-chat__message-actions-list button:first-child {
  display: none;
}

.str-chat__list .str-chat__reverse-infinite-scroll {
  padding-top: 0px;
}

.str-chat__thread-list .channel-empty__container {
  display: none;
}

.str-chat__date-separator {
  padding: 20px 40px;
}

.custom-thread-header {
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
}

.custom-thread-header__left {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.custom-thread-header__left-title {
  font-family: Helvetica Neue, sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #2c2c30;
  margin-right: 10px;
}

.custom-thread-header__left-count {
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
  color: #858688;
}

.close-thread-icon {
  cursor: pointer;
  margin-right: 10px;
}

.str-chat__list--thread
  .str-chat__message-simple__actions__action--options
  .str-chat__message-actions-box {
  left: initial;
  right: 100%;
  border-radius: var(--border-radius-md);
}

.channel-empty__container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 20px;
}

.channel-empty__avatars {
  display: flex;
}

.channel-empty__avatars div:first-child {
  z-index: 3;
}

.channel-empty__avatars div:nth-child(2) {
  position: relative;
  right: 32px;
  z-index: 2;
}

.channel-empty__avatars div:nth-child(3) {
  position: relative;
  right: 64px;
  z-index: 1;
}

.channel-empty__avatars .str-chat__avatar {
  margin-right: 0;
}

.channel-empty__first {
  font-family: Helvetica Neue, sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 120%;
  color: #2c2c30;
  margin-bottom: 10px;
}

.channel-empty__first .channel-empty__user-name {
  color: var(--primary-color);
}

.channel-empty__second {
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
  line-height: 120%;
  margin: 0;
  color: #858688;
}

.team-channel-header__container {
  position: relative;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background: #ffffff;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  z-index: 1;
}

.team-channel-header__channel-wrapper {
  display: flex;
  align-items: center;
}

.team-channel-header__channel-wrapper svg {
  cursor: pointer;
}

.team-channel-header__name {
  font-family: Helvetica Neue, sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #2c2c30;
  margin-right: 8px;
}

.team-channel-header__name-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.team-channel-header__name-multi {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.team-channel-header__name-multi:nth-child(3) {
  margin-right: 0px;
}

.team-channel-header__name-wrapper .str-chat__avatar {
  margin-right: 8px;
}

.team-channel-header__name.user {
  font-weight: 500;
  font-size: 14px;
}

.team-channel-header__right {
  position: absolute;
  display: flex;
  align-items: center;
  right: 0;
  padding: 0 20px;
  background: #ffffff;
}

.team-channel-header__right-pin-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.team-channel-header__right svg {
  margin-left: 16px;
  margin-right: 4px;
}

.team-channel-header__right-text {
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
  color: #858688;
}